<template>
  <div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">
    <div id="page-content">
      <!-- <div class="dispflex">
        <h1 class="hdrtitle ml-2" style="margin-top: 0px;">School Branch</h1>
        <button type="button" class="backbtn" @click="previousRoute">
               <i class="fa fa-arrow-left"></i>
              </button>
      </div> -->
      <div class="webNew-dispflex"   >
       
       <div class="backbox">
         <div class="back-arrow" @click="previousRoute">
           <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
         </div>

       </div>
       <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
        School Branch
       </h4>
      </div>

      <div class="row mt-2">
        <div class="col-lg-12">
          <div class="widjet">
            <div class="widjethdr dispflex">
              <div style="width: auto">
                <span class="iconsect blubg" style="padding-left: 11px"
                  ><i class="fas fa-building"></i
                ></span>
                <h1>View School Branches</h1>
              </div>
              <div class="mt-2 mr-3">
                <button
                  class="btn gry_bg tooltipt mpopup"
                  v-b-modal.addschoolbranchpop
                >
                  <i class="fas fa-building"></i>
                  <span class="tooltiptext">Add Branch</span>
                </button>
              </div>
            </div>
            <div class="widjetcontent">
              <div class="restable">
                <div class="resrow resheader">
                  <div class="cell" style="width: 40%;padding-left: 18px;">Branch Name</div>
                  <div class="cell" style="padding-left: 14%;">Status</div>
                  <div class="cell">Action</div>
                </div>
                <div
                  class="resrow"
                  v-for="(data, index) in branchList"
                  :key="index"
                >
                  <div class="cell" data-title="From Month" style="padding-left: 18px;width: 11%;">
                    {{ data.name }}
                  </div>
                  <div class="cell" data-title="From Year"   :style="data.isActive ? 'color: green;padding-left: 14%;' : 'color: red;padding-left: 14%;'">
                    {{ data.isActive ? 'Active' : 'In-Active' }}
                  </div>

                  <div class="cell" data-title="Action">
                    <button
                      :disabled="data.apiName == 'main_branch'"
                      class="btn round orange tooltipt" @click="editBranch(data)"
                    >
                      <i class="fas fa-edit"></i
                      ><span class="tooltiptext">Edit</span>
                    </button>
                    <button class="btn round red tooltipt"  :disabled="data.apiName == 'main_branch' || !data.isActive">
                      <i class="far fa-trash-alt"></i>
                      <span class="tooltiptext">Delete</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="widjetfooter text-right">
              <div class="dk_iconsml">
                <button
                  type="button"
                  class="btn btnsml"
                  @click="$router.go(-1)"
                >
                  <i class="fa fa-arrow-left"></i> <span>Back</span>
                </button>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <b-modal id="addschoolbranchpop" class="modal">
      <button type="button" class="close"  @click.prevent="closeBranchPopUp">×</button>

      <div class="contact-form">
        <h2 id="poptitle">Add School Branch</h2>
        <br />
        <div id="extFields">
          <form
             data-vv-scope="userValidate"
              id="addteacher"
              accept-charset="utf-8"
          >
            <div class="form-row">
              <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                <span class="has-float-label">
                  <input
                    v-model="branchDetails.name"
                    type="text"
                    name="Branch Name"
                    v-validate="'required'"
                    class="form-control form-input"
                    id="schlnameid"
                  />
                  <label
                      v-if="errors.first('userValidate.Branch Name')"
                      class="vi-error"
                      for="emailid"
                      style="top: -1em"
                      >Branch Name is required</label
                    >
                  <label for="schlnameid" v-else
                    >Branch Name <span class="required">*</span></label
                  >
                </span>
              </div>
              <!-- <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <input
                    v-model="branchDetails.status"
                    type="text"
                    disabled
                    name="affiliate"
                    value=""
                    class="form-control form-input"
                    id="affid"
                  />
                  <label for="affid"
                    >Status <span class="required">*</span></label
                  >
                </span>
              </div> -->
              <div class="form-group input-group col-md-6">
                  <span class="web-has-float-label">
                    <input
                      @input="checkDomainName"
                      type="text"
                      name="domainName"
                      v-validate="{ required: true, regex: /^[a-zA-Z0-9]*$/}"
                      class="form-control web-form-input"
                      id="lnameid"
                      v-model="branchDetails.domainName"
                      maxlength="50"
                    />
                  
                    <label
                      v-if="(errors.first('userValidate.domainName') && showError) || !isDomainNameAvailable"
                      class="vi-error"
                      style="top: -1em"
                      >{{
                        errors.first("schoolRegister.domainName")
                          ? "Domain Name is required And not contain any special characters"
                          : "Domain Name already registered. please use a different one."
                      }}</label
                    >
                    
                    <label for="lnameid" v-else
                      >Domain Name <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-md-3">
                  <span class="web-has-float-label">
                    <input
                      @input="checkAffilliateId"
                      type="text"
                      name="affid"
                      v-validate="'required'"
                      v-model="branchDetails.affilliateId"
                      class="form-control web-form-input"
                      id="affid"
                      maxlength="50"
                      autocomplete="off"
                      style="cursor: text;"
                    />

                    <label
                      v-if="
                        (errors.first('userValidate.affid') && showError) ||
                          !isAffiliateIdAvailable
                      "
                      class="vi-error"
                      style="top: -1em"
                      >{{
                        errors.first("userValidate.affid")
                          ? "Affiliate Id is required"
                          : "Affiliate Id already registered. please use a different one."
                      }}</label
                    >

                    <label for="affid" v-else
                      >Affiliate ID <span class="required">*</span></label
                    >
                  </span>
                </div>
               
               
                <div class="form-group input-group col-md-6 ">
                  <span class="web-has-float-label">
                    <input
                      type="text"
                      disabled
                      class="form-control form-input"
                      id="countryid"
                      v-model="branchDetails.country"
                    />

                    <label for="countryid"
                      >Country<span class="required">*</span></label
                    >
                    <!-- <datalist id="countries">
                      <option value="India">India</option>
                    </datalist> -->
                  </span>
                </div>

                <div class="form-group input-group col-md-6">
                  <span class="web-has-float-label">
                    <vue-google-autocomplete
                      ref="address"
                      id="map"
                      class="form-control form-input"
                      country="IN"
                      types=""
                      v-on:placechanged="getAddressData"
                    >
                    </vue-google-autocomplete>
                    <label for="countryid"
                      >Address<span class="required">*</span></label
                    >
                    <!-- <datalist id="countries">
                      <option value="India">India</option>
                    </datalist> -->
                  </span>
                </div>
                <div class="form-group input-group col-md-6">
                  <span class="web-has-float-label">
                    
                    <input
                      type="text"
                      v-model="branchDetails.state"
                      class="form-control form-input"
                      v-validate="'required|alpha_spaces|max:100'"
                      name="stateValidate"
                    />

                    <label
                      v-if="
                        errors.first('userValidate.stateValidate') &&
                          showError
                      "
                      class="vi-error"
                      style="top: -1em"
                      >State is required</label
                    >

                    <label for="stateid" v-else
                      >State<span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6">
                  <span class="web-has-float-label">
                    <input
                      type="text"
                      v-model="branchDetails.district"
                      class="form-control form-input"
                      v-validate="'required|alpha_spaces|max:100'"
                      name="districtValidate"
                    />

                    <label
                      v-if="
                        errors.first('userValidate.districtValidate') &&
                          showError
                      "
                      class="vi-error"
                      style="top: -1em"
                      >District is required</label
                    >

                    <label for="stateid" v-else
                      >District<span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6">
                  <span class="web-has-float-label">
                    <input
                      type="text"
                      v-model="branchDetails.city"
                      class="form-control form-input"
                      id="cityid"
                      v-validate="'required|alpha_spaces|max:100'"
                      name="cityValidate"
                    />

                    <label
                      v-if="
                        errors.first('userValidate.cityValidate') && showError
                      "
                      class="vi-error"
                      style="top: -1em"
                      >City/Town is required</label
                    >

                    <label for="stateid" v-else
                      >City / Town<span class="required">*</span></label
                    >
                  </span>
                </div>

                <div class="form-group input-group col-md-6">
                  <span class="web-has-float-label">
                    <input
                      type="text"
                      v-validate="'required|numeric|max:6|min:6'"
                      v-model="branchDetails.pincode"
                      class="form-control form-input"
                      name="PinCodeValidate"
                    />

                    <label
                      v-if="
                        errors.first('userValidate.pinCodeValidate') &&
                          showError
                      "
                      class="vi-error"
                      style="top: -1em"
                      >Pin Code is required</label
                    >

                    <label for="stateid" v-else
                      >Pin Code<span class="required">*</span></label
                    >
                  </span>
                </div>

                <div class="form-group input-group col-md-6 pr-md-0">
                  <span class="web-has-float-label">
                    <input
                      v-validate="'required'"
                      type="text"
                      name="stname"
                      class="form-control web-form-input"
                      id="stnameid"
                      v-model="branchDetails.street"
                      maxlength="50"
                    />

                    <label
                      v-if="errors.first('userValidate.stname') && showError"
                      class="vi-error"
                      style="top: -1em"
                      >Street Name is required</label
                    >
                    <label for="stnameid" v-else
                      >Street Name<span class="required">*</span></label
                    >
                  </span>
                </div>
              
              <div
                            class="form-group input-group col-md-4 mt-3"
                            style="border-bottom: 1px solid #e5e5e5"
                          >
                            <span style="color: #6c757d" class="mr-3"
                              >Create an admin for this Branch?
                             </span
                            >
                            <label class="switch">
                              <input type="checkbox"  @change="checkIsAdminUser" v-model="branchDetails.isAdminUser"  id="togBtn" />
                              <div class="slider"></div>
                            </label>
                          </div>
              <!-- <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <input
                      type="text"
                      name="streetname"
                      value=""
                      class="form-control form-input"
                      id="strnamid"
                    />
                    <label for="strnamid"
                      >School Name <span class="required">*</span></label
                    >
                  </span>
                </div> -->
              <!-- <div class="form-group input-group col-md-6 mt-3">
                  <span class="has-float-label">
                    <input
                      list="statelist"
                      value=""
                      class="form-control form-input"
                      id="statelistid"
                    />
                    <label for="statelistid"
                      >School Type <span class="required">*</span></label
                    >
                    <datalist id="statelist">
                      <option value="Tamilnadu">Tamilnadu</option>
                    </datalist>
                  </span>
                </div> -->
              <!-- <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <input
                      type="text"
                      name="prntac"
                      class="form-control form-input"
                      id="prntacid"
                    />
                    <label for="prntacid"
                      >Admin Name <span class="required">*</span></label
                    >
                  </span>
                </div> -->
              <!-- <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <input
                    v-model="branchDetails.email"
                    type="text"
                    name="achie"
                    value=""
                    class="form-control form-input"
                    id="achieid"
                  />
                  <label for="achieid"
                    >Email <span class="required">*</span></label
                  >
                </span>
              </div> -->
              <!-- <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <input
                    v-model="branchDetails.mobileNumber"
                    type="text"
                    name="achie"
                    value=""
                    class="form-control form-input"
                    id="achieid"
                  />
                  <label for="achieid"
                    >Mobile Number <span class="required">*</span></label
                  >
                </span>
              </div> -->
            </div>
           
            <fieldset class="accordion-group-container">
              <label
                style="margin: 0"
                for="accordiongroup-2"
                class="blutitle accordion-label"
              >
                BRANCH ADMIN DETAILS
              </label>
              <div class="form-row">
                <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <input
                      type="text"
                      name="First Name"
                      class="form-control form-input"
                      id="tchrnameid"
                      :disabled="!branchDetails.isAdminUser"
                      v-model="user.firstName"
                      placeholder="First Name"
                      v-validate="branchDetails.isAdminUser == true ? 'required' : false"
                    />
                    <label
                      v-if="errors.first('userValidate.First Name')"
                      class="vi-error"
                      for="emailid"
                      style="top: -1em"
                      >First Name is required</label
                    >
                    <label for="tchrnameid" v-else
                      >First Name <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <input
                      type="text"
                      name="lastName"
                      :disabled="!branchDetails.isAdminUser"
                      class="form-control form-input"
                      id="lastName"
                      v-model="user.lastName"
                      placeholder="Last Name"
                      v-validate="branchDetails.isAdminUser == true ? 'required' : false"
                    />
                    <label
                      v-if="errors.first('userValidate.lastName')"
                      class="vi-error"
                      for="emailid"
                      style="top: -1em"
                      >Last Name is required</label
                    >
                    <label for="lastName" v-else
                      >Last Name <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3">
                  <span class="has-float-label">
                    <input
                      type="number"
                      :disabled="!branchDetails.isAdminUser"
                      onKeyPress="if(this.value.length==10) return false;"
                      v-validate="branchDetails.isAdminUser == true ? 'required|numeric|min:10|max:10' : false"
                      name="mbnoUser"
                      v-model="user.phoneNo"
                      class="form-control form-input"
                      id="mbnoUser"
                      placeholder="Mobile"
                    />

                    <label
                      v-if="errors.first('userValidate.mbnoUser')"
                      class="vi-error"
                      style="top: -1em"
                    >
                      Please enter a valid mobile number(10 digits)</label
                    >
                    <label v-else>Mobile <span class="required">*</span></label>
                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <input
                      autocomplete="false"
                      name="emailTest"
                      v-validate="branchDetails.isAdminUser == true ? 'required|email' : false"
                      :disabled="!branchDetails.isAdminUser"
                      v-model="user.email"
                      class="form-control form-input"
                      id="emailTest"
                      placeholder="Email Id"
                    />

                    <label
                      v-if="errors.first('userValidate.emailTest')"
                      class="vi-error"
                      style="top: -1em"
                    >
                      {{
                        errors.first("userValidate.emailTest")
                          ? "Email Id is required and (eg: abc@gmail.com)"
                          : "Email already exists in this or another Vidhyaan School Account. It must be unique across all Accounts."
                      }}</label
                    >
                    <label v-else
                      >Email Id <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <input
                      autocomplete="new-password"
                      name="passwordTest"
                      ref="password"
                      :disabled="!branchDetails.isAdminUser"
                      type="password"
                      class="form-control form-input"
                      placeholder="Password"
                      v-validate="branchDetails.isAdminUser == true ? 'required' : false"
                      v-model="user.password"
                    />
                    <label
                      v-if="errors.first('userValidate.passwordTest')"
                      class="vi-error"
                      for="emailid"
                      style="top: -1em"
                      >Password is required</label
                    >
                    <label for="tchrnameid" v-else
                      >Password <span class="required">*</span></label
                    >
                  </span>
                </div>
              </div>
            </fieldset>
            <div
              class="mt-3 pt-3"
              style="border-top: 1px solid #e5e5e5"
            >
              <!-- <div class="dk_icon"></div> -->
              <div class="text-center dk_iconsml">
                <button
                  type="button"
                  class="btn btnsml"
                  id="svbtn"
                  @click.prevent="saveBranch"
                >
                    <span>Save</span>
                </button>
                <button
                  type="button"
                  @click.prevent="closeBranchPopUp"
                  class="btn btncl clsmdl"
                  id="clbtn"
                >
                     <span>Cancel</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </b-modal>
    <b-modal id="editschoolbranchpop" class="modal">
      <button type="button" class="close"  @click.prevent="closeBranchEditPopUp">×</button>

      <div class="contact-form">
        <h2 id="poptitle">Edit School Branch</h2>
        <br />
        <div id="extFields">
          <form
             data-vv-scope="brancEditValidate"
              id="addteacher"
              accept-charset="utf-8"
          >
            <div class="form-row">
              <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                <span class="has-float-label">
                  <input
                    v-model="branchEditDetails.name"
                    type="text"
                    name="Branch Name"
                    v-validate="'required'"
                    class="form-control form-input"
                    id="schlnameid"
                  />
                  <label
                      v-if="errors.first('brancEditValidate.Branch Name')"
                      class="vi-error"
                      for="emailid"
                      style="top: -1em"
                      >Branch Name is required</label
                    >
                  <label for="schlnameid" v-else
                    >Branch Name <span class="required">*</span></label
                  >
                </span>
              </div>
            
              <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                <span class="has-float-label">
                  <input
                    v-model="branchEditDetails.location"
                    type="text"
                    name="location"
                    v-validate="'required'"
                    class="form-control form-input"
                    id="strnamid"
                  />
                  <label
                      v-if="errors.first('brancEditValidate.location')"
                      class="vi-error"
                      for="emailid"
                      style="top: -1em"
                      >Location is required</label
                    >
                  <label for="strnamid" v-else
                    >Location<span class="required">*</span></label
                  >
                </span>
              </div>

              <div
                            class="form-group input-group col-md-4 mt-3"
                            style="border-bottom: 1px solid #e5e5e5"
                          >
                            <span style="color: #6c757d" class="mr-3"
                              >Branch Status Active?
                             </span
                            >
                            <label class="switch">
                              <input type="checkbox"   v-model="branchEditDetails.isActive"  id="togBtn" />
                              <div class="slider"></div>
                            </label>
                          </div>
              
             
            
            </div>
          
            <div
              class="mt-3 pt-3"
              style="border-top: 1px solid #e5e5e5"
            >
              <!-- <div class="dk_icon"></div> -->
              <div class="text-center dk_iconsml">
                <button
                  type="button"
                  class="btn btnsml"
                  id="svbtn"
                  @click.prevent="updateBranch"
                >
                    <span>Update</span>
                </button>
                <button
                  type="button"
                  @click.prevent="closeBranchEditPopUp"
                  class="btn btncl clsmdl"
                  id="clbtn"
                >
                     <span>Cancel</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  name: "vi-admission-school-branch",
  components: {
    VueGoogleAutocomplete
  },
  data() {
    return {
      branchDetails: {
        name: "",
        isAdmin: false,
        status: "Active",
        isAdminUser: false,
        location: "",
        email: "",
        mobileNumber: "",
        address: '',
        affilliateId: '',
        domainName: '',
        state: '',
        pincode: '',
        district: '',
        country: "India",
        email: '',
        city: '',
        longitude: '',
        latitude: '',
        street: ''
      },
      branchEditDetails: {
        name: "",
        location: '',
        isActive: false,
        _id: null,
      },
      user: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        phoneNo: null,
        role: null,
        isActive: true,
        branchId: null,
      },
      address: "",
      marker: { position: { lat: 13.061931068989976, lng: 80.25831401348114 } },
      center: { lat: 13.061931068989976, lng: 80.25831401348114 },
      
      mapOptions: {
        disableDefaultUI: true,
      },
      // address: "",
      // marker: { position: { lat: 13.061931068989976, lng: 80.25831401348114 } },
      // center: { lat: 13.061931068989976, lng: 80.25831401348114 },
      statusList: ["Active", "In Active"],
      branchList: [],
      isAffiliateIdAvailable: true,
      isDomainNameAvailable: true,
      showError: true
    };
  },
  created() {
    this.getBranchDetails();
  },
  mounted() {
    this.panToMarker();
  },
  computed: {
    styles() {
      var brwHeight = window.innerHeight;
      return {
        height: brwHeight - 90 + "px",
      };
    },
  },
  methods: {
    getAddressData(addressData, placeResultData, id) {
      this.isAddressSelected = true;

      this.streetName = addressData.route || "",
      this.state = addressData.administrative_area_level_1 || "",
      this.district = addressData.administrative_area_level_2 || "",
      this.pincode = addressData.postal_code || "";
      this.city = addressData.locality || "";

      this.marker.position.lng = addressData.longitude || "";
      this.marker.position.lat = addressData.latitude || "";

     // this.panToMarker();

      if (this.state) {
        this.state = this.stateList[this.state];
      }

      },
      async checkAffilliateId() {
      const response = await ViService.viGetOnly(
        `/common/checkApiName?cName=Account&affilliateId=${this.affiliateID}`
      );

      if (response.isSuccess) {
        this.isAffiliateIdAvailable = response.isApiNameAvailable;
      } else {
        this.$toasted.error(response.message);
         if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
      }
    },
     async checkDomainName() {
      const response = await ViService.viGetOnly(
        `/common/checkApiName?cName=Account&domainName=${this.branchDetails.domainName}`
      );

      if (response.isSuccess) {
        
        this.isDomainNameAvailable = response.isApiNameAvailable;
      } else {
        this.$toasted.error(response.message);
         if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
      }
    },
    panToMarker() {
      
      this.$refs.mapRef.panTo(this.marker.position);
    },
    previousRoute(){
     let data = localStorage.getItem('previousTab');
     localStorage.setItem("activeTab",data);
     localStorage.setItem("previousTab",data);
     this.$router.go(-1)
    },
    editBranch(data){
      
      //this.branchEditDetails: {
        this.branchEditDetails.name = data.name,
        this.branchEditDetails.location = data.location,
        this.branchEditDetails.isActive = data.isActive,
        this.branchEditDetails._id = data._id
      //},
      this.$bvModal.show("editschoolbranchpop");
 
    },
    closeBranchEditPopUp(){
      this.branchEditDetails.name = ''
        this.branchEditDetails.location = ''
        this.branchEditDetails.isActive = false
        this.branchEditDetails._id = null
      this.$bvModal.hide("editschoolbranchpop");
    },
    
    async saveBranch() {
      // let isFormValid = false;

      // await this.$validator.validateAll("departConfig").then((result) => {
      //   isFormValid = result;
      // });
      let isFormValid = false;

await this.$validator.validateAll("userValidate").then((result) => {
  isFormValid = result;
});
     if (isFormValid) {
        
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        this.user.phoneNo = Number(this.user.phoneNo)

        let obj = {
          branchDetails : this.branchDetails,
          userDetails: this.user
        }
        const response = await ViService.viXPost(
          `/account/saveBranch`,
          obj,
          userData.token
        );

        if (response.isSuccess) {
          // let getBranchDetails = this.$store.getters.getBranchList;
          //   getBranchDetails.push({
          //     name: this.userDetail.branch.name,
          //     _id: this.userDetail.branch._id,
          //   });

          // this.$store.dispatch("setBranchList",getBranchDetails);

          this.closeBranchPopUp();
          this.getBranchDetails();
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    }
    },
    async updateBranch() {
      // let isFormValid = false;

      // await this.$validator.validateAll("departConfig").then((result) => {
      //   isFormValid = result;
      // });
      let isFormValid = false;

await this.$validator.validateAll("brancEditValidate").then((result) => {
  isFormValid = result;
});
     if (isFormValid) {
        
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXPost(
          `/account/updateBranch`,
          this.branchEditDetails,
          userData.token
        );

        if (response.isSuccess) {
          
          this.closeBranchEditPopUp();
          this.getBranchDetails();
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    }
    },
    
    checkIsAdminUser(){
      if(!this.branchDetails.isAdminUser){
       
          this.user.firstName = "",
          this.user.lastName = "",
          this.user.email = "",
          this.user.password = "",
          this.user.phoneNo = null
      }
      
    },
    async getBranchDetails() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        //this.viLoader = true
        const response = await ViService.viXGet(
          "/account/getBranchDetails",
          userData.token
        );

        if (response.isSuccess) {
          let setBranchs = [];
          const details = secureUI.secureGet(response.data);
          
          this.branchList = details;

          for (let x of this.branchList) {
            setBranchs.push({
              name: x.name,
              _id: x._id,
              isActive: x.isActive
            });
          }

          this.$store.dispatch("setBranchList", setBranchs);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
        // this.viLoader = false
      }
    },

    closeBranchPopUp() {
      this.$bvModal.hide("addschoolbranchpop");
      this.branchDetails = {
        name: "",
        isAdmin: false,
        status: "Active",
        location: "",
        email: "",
        mobileNumber: "",
      };
      this.user = {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        phoneNo: null,
        role: null,
        isActive: true,
        branchId: null,
      }
    },
  },
  // }
};
</script>
